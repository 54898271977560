import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Software/Windows/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision v3 for Windows",
  "path": "/Software/Windows/InstarVision/Advanced/Player/",
  "dateChanged": "2018-01-25",
  "author": "Mike Polinowski",
  "excerpt": "The INSTAR Player is a separate software that can be used to quickly stream through your cameras recordings",
  "image": "../../P_SearchThumb_InstarVision_Windows.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Windows.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='InstarVision v3 for Windows' dateChanged='2018-01-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The InstarVision Surveillance Center is an IP camera software which can support 64 cameras at the same time, including Network cameras, USB cameras remotely accessed secondary installations of the software.' image='/images/Search/P_SearchThumb_InstarVision_Windows.png' twitter='/images/Search/P_SearchThumb_InstarVision_Windows.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/InstarVision/Advanced/Player/' locationFR='/fr/Software/Windows/InstarVision/Advanced/Player/' crumbLabel="Player" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0e777c1473a4459d1836ab73bfab3605/29114/Banner_en-InstarVision2-0_Advanced.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAe0lEQVQI143HsQrCMBSF4fsq+nwObordHZrrFHERJ/sqLq4RWnF3cGxSQwzlF1pn6YGPwy/0CaKHGCB18G7HJjN11lrWmwJyRIwLqPPorUPvGW1Gpvlg6vRXWSd2j55iq6yWC0rnETm9kOOT4SuPnH+qqQLz/YXZ4Tr0F9aiwNov85E3AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e777c1473a4459d1836ab73bfab3605/e4706/Banner_en-InstarVision2-0_Advanced.avif 230w", "/en/static/0e777c1473a4459d1836ab73bfab3605/d1af7/Banner_en-InstarVision2-0_Advanced.avif 460w", "/en/static/0e777c1473a4459d1836ab73bfab3605/7f308/Banner_en-InstarVision2-0_Advanced.avif 920w", "/en/static/0e777c1473a4459d1836ab73bfab3605/e1c99/Banner_en-InstarVision2-0_Advanced.avif 1380w", "/en/static/0e777c1473a4459d1836ab73bfab3605/76ea5/Banner_en-InstarVision2-0_Advanced.avif 1840w", "/en/static/0e777c1473a4459d1836ab73bfab3605/f696c/Banner_en-InstarVision2-0_Advanced.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0e777c1473a4459d1836ab73bfab3605/a0b58/Banner_en-InstarVision2-0_Advanced.webp 230w", "/en/static/0e777c1473a4459d1836ab73bfab3605/bc10c/Banner_en-InstarVision2-0_Advanced.webp 460w", "/en/static/0e777c1473a4459d1836ab73bfab3605/966d8/Banner_en-InstarVision2-0_Advanced.webp 920w", "/en/static/0e777c1473a4459d1836ab73bfab3605/445df/Banner_en-InstarVision2-0_Advanced.webp 1380w", "/en/static/0e777c1473a4459d1836ab73bfab3605/78de1/Banner_en-InstarVision2-0_Advanced.webp 1840w", "/en/static/0e777c1473a4459d1836ab73bfab3605/882b9/Banner_en-InstarVision2-0_Advanced.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e777c1473a4459d1836ab73bfab3605/81c8e/Banner_en-InstarVision2-0_Advanced.png 230w", "/en/static/0e777c1473a4459d1836ab73bfab3605/08a84/Banner_en-InstarVision2-0_Advanced.png 460w", "/en/static/0e777c1473a4459d1836ab73bfab3605/c0255/Banner_en-InstarVision2-0_Advanced.png 920w", "/en/static/0e777c1473a4459d1836ab73bfab3605/b1001/Banner_en-InstarVision2-0_Advanced.png 1380w", "/en/static/0e777c1473a4459d1836ab73bfab3605/161ec/Banner_en-InstarVision2-0_Advanced.png 1840w", "/en/static/0e777c1473a4459d1836ab73bfab3605/29114/Banner_en-InstarVision2-0_Advanced.png 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0e777c1473a4459d1836ab73bfab3605/c0255/Banner_en-InstarVision2-0_Advanced.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "instar-player",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-player",
        "aria-label": "instar player permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Player`}</h2>
    <p>{`The INSTAR Player is a stand-alone tool for viewing video recordings. You can simply start it from InstarVision 2.x by clicking on `}<strong parentName="p">{`Advanced`}</strong>{` and the `}<strong parentName="p">{`Player`}</strong>{` button.`}</p>
    <p>{`To play recordings you have 3 different views available. The `}<a parentName="p" {...{
        "href": "#recordings-view"
      }}><strong parentName="a">{`Recordings View`}</strong></a>{` to play the videos recorded by InstarVision, the `}<a parentName="p" {...{
        "href": "#sd-card-view"
      }}><strong parentName="a">{`SD Card View`}</strong></a>{` to view / download videos from INSTAR cameras contains a SD card, and the `}<a parentName="p" {...{
        "href": "#media-view"
      }}><strong parentName="a">{`Media View`}</strong></a>{` to view other videos. The view selection itself can be opened and closed by clicking on the thin centered bar between the video display and the view. At the bottom of the video display are the classic video player elements with timeline, the start / pause button, the fast forward and rewind buttons, and the stop button. The volume can be increased with a click on the right of the volume display and reduced with a click to the left of it. In addition, with the folder icon any video file can be selected on the PC, which is then played directly. The button with the squares, switches the layout of the video display areas. More details about can be found in the next section.`}</p>
    <h3 {...{
      "id": "recordings-view",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#recordings-view",
        "aria-label": "recordings view permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Recordings View`}</h3>
    <p>{`After starting the INSTAR Player from the InstarVision, it will automatically find all recording paths and list them in the first column of the Recordings View. Select the desired recording path. In the second column you will see the date on which video recordings exists. After selecting it, the video channel will be displayed in the third column. As soon as you have selected the desired channel, the associated video recordings with timestamp appears in the last column. A double click starts the video. If several videos exists for one channel, the next one in the list is automatically started after the first video has been finished. This allows you to conveniently view all alarm recordings of a channel.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e30bf3e8b814c00da329b300656249f5/6af66/Recordings_View.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "105.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAExklEQVQ4y12UWUxUVxzGL61VVKCmD42WwQcTmzZ96aOxQimyby5opQYQEyht48gywx5AGLYZmWFAtgFmhiWNiS9t06Tpkr5Yi4YWRRiXthYUXAsODjB0BsRfcw4d0vQmX+6cc77/73z/e+4dZWx4lNGhEUYvX127S11dH49dGcUxPMb48HWuX7m2vn5taETO/fDVd9h6BvhaZ+CnyjqUA1EHUBSFLf4B+G/awmb/rWzauHldG1/xX9d/530S/sCtQfi9tIH9+6JRSvJK2B8VSVJyMgmJicTExHDo8GHS0tNIz8gg8+RJMk9mciIzU46PHz/OsdRUjhw9QmxcHKFhYezdu5e33nmbjA8zUAa7B6iqrqKutpaqykpqa2vp7e1lYGCA8+fPc+HCBSnxe3BwELvdTnd3N+3t7RgMBvLy8khPTycqOoqcEzkolhYLefl5FBUVSTU3N0uzKLLabNhsdrmBxWKhq6uLtrY2Wlpa0Ov16HQ6iouLSUtLIzQslOz0bJQucxe5ebloCjScPXtWFohim81GX18fVqtVwoUEsKOjQwJFOgEsLy+XKaNioshKy0LpNHWQlZ2FrkaH2WyWQJnOaqWnp0dCfCCRvLW1VXYhgNXV1ZSUlEhg6kepfCJaNlTrUZ9WY9AbMJlMskgkFBIQH8jXqoAJX0NDA1VVVWi1WtSn1Bw8dJBjyR+itDSa0RZpaahvwGg0ygQ+gO9+7tw5CRLrQr7nJ9rNz88nJyeH3W/uJv6DOJS2pjbyNQXydEUbokAkEGkEyNeimG9qaqKxsZG6ujqZrrCwELVaTei+UIJeDSIpMhGlVd+COve0NIhdhVkkEMW+ROKwhARMbCy84tkVFBQQHR2NSqVi22vbiI+IRzHVm8j5NEcaysrKpFkU1dfXy+fkk5irqamhoqJCvioajYakpCRCQkIkMDAokNj3Y1CMtU1kf5wtDSXFxVRWVsrTE6cu0gqJ5GJOrJWWlspWU1JS2LVrFzt37pTAgMCANeDRpKNsDgxgR7CK7cEq3lCpCA4RCkElzP9KzIm1HcHBvL59Oy9v2IDip6D4+a1JUdjz7h6USk0Vke+FkRweTlJ4OImRcSREJhEfmUBCZCLx++PXFJlIbEQ8cRFinEByzAGSYw9KpSQcJi4iDu1nhSgXL17i1riDB5N/8ptjjNGRq4xfd3Bj/CaOMQf37t5nauoRztmnLM4vsuBawDXnwjnjxDnrxDnzlN8fzjL1+CnOmVmUNksPldXVtHd00mWxyK/FZDLS292JtbeHDqMO85lcGvQGLN0W7H12+vv7MZqM8tUSfkOzGYPRhN1uQ7k/NcXKygoLC4t4PB7E9Xh2jmHHHW4/mMPrfgbuJyy5F7k38QdulxNYlV6v1yu17PWwurrK9NQUyqOHD/n/dXvGS98vTvp/deJc24PpmXmGHJPcmJ5jcRl4sSohQs+fP5cewVImJyfxrsKix4vbu4zbu4Ln7yVY8fCCVdxLSyx7lvj+5gw9l/+iY2iWkbtzeNzzPHO5cM3P43K5cLvdTExMoJjtn6P5ZoTiL39G+8Ulir69hra0HE1ZBUU/3qJI14juTBVt5iam79zk2ZNprJZOTp1SU1ioRavRyD+IvNxcDHo9/wB7pEVLkM8DYwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e30bf3e8b814c00da329b300656249f5/e4706/Recordings_View.avif 230w", "/en/static/e30bf3e8b814c00da329b300656249f5/d1af7/Recordings_View.avif 460w", "/en/static/e30bf3e8b814c00da329b300656249f5/e6133/Recordings_View.avif 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e30bf3e8b814c00da329b300656249f5/a0b58/Recordings_View.webp 230w", "/en/static/e30bf3e8b814c00da329b300656249f5/bc10c/Recordings_View.webp 460w", "/en/static/e30bf3e8b814c00da329b300656249f5/0ba47/Recordings_View.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e30bf3e8b814c00da329b300656249f5/81c8e/Recordings_View.png 230w", "/en/static/e30bf3e8b814c00da329b300656249f5/08a84/Recordings_View.png 460w", "/en/static/e30bf3e8b814c00da329b300656249f5/6af66/Recordings_View.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e30bf3e8b814c00da329b300656249f5/6af66/Recordings_View.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Above the channel column is placed the `}<strong parentName="p">{`Multi Window`}</strong>{` button, marked by a square containing 4 smaller squares. With this you can switch the layout of the video display. This allows you to watch multiple videos at the same time. Click on the area in the video ad. The area is then outlined in pink. Then in the Recordings View, select the channel and click on the first video. This is then displayed in the bordered area. To view the recordings in parallel, repeat the process for all the other channels.`}</p>
    <p>{`The Recordings View also gives you the ability to evaluate recordings at a specific time. Let's say you have 4 cameras installed in your house. The house has several entrances. Each camera is set in a separate channel and you have the `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Record/Continuous_Recording"
      }}>{`Continuous Alarm`}</a>{` recording active, with for each of them records 20-minute videos. The postman claims he has put a package at 2pm and you want to check this. Since you do not know on which entrance he has put the package, you must browse all the corresponding channels and since the videos are 20 minutes long, you have to forward to the 2pm. But this can be done easierly:
Select the 4 channel layout with the `}<strong parentName="p">{`Multi Window`}</strong>{` button. In the Recording View, you hook all the channels on where an entrance can be seen. Below the 4th column is a time entry field. Set the 2pm time there, so "14:00:00" and click the `}<strong parentName="p">{`Play`}</strong>{` button on the left side of the time entry field. All videos of the selected channels will now be played simultaneously, from the 2pm time on. So you can quickly determine whether the postman has put the package according to his claim in front of a house entrance.`}</p>
    <p>{`The buttons on the left can be used to load the currently active recording path (`}<strong parentName="p">{`Get`}</strong>{` button) or add or delete further video paths (`}<strong parentName="p">{`Add`}</strong>{` and `}<strong parentName="p">{`Del`}</strong>{` buttons). It is just an addition and deletion of the recording paths. The video files and the recording path itself are still retained on the hard disk if you delete them .`}</p>
    <h3 {...{
      "id": "sd-card-view",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sd-card-view",
        "aria-label": "sd card view permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SD Card View`}</h3>
    <p>{`Click on the small left arrow in the head of the first column to go to the SD card view. This is used to download and view pictures stored at the SD card of an INSTAR camera. This is useful if you do not use the InstarVision motion detection but them from the INSTAR camera itself. If you have started the player from InstarVision, the available channels are already listed in the first column. If this is not the case, then click on the button (`}<strong parentName="p">{`Get`}</strong>{` button) under the first column. Mark the desired channel. If it is an INSTAR camera and there exists picture or video recordings, the contents of the SD card are displayed in the other columns. Since INSTAR cameras create a new folder per recording day, the name of the date folder is in the second column. Select here the desired folder, so that in the third column the picture or video folder (imagesXXX or recordXXX with XXX as counter of all 100 files) is displayed. After selecting it, the image or video files are displayed in the last column. Double-clicking on a picture or a video recording will show it in the first video area.`}</p>
    <p>{`By double-clicking, the recording from the SD card is only temporarily stored for displaying. You can also download the pictures from the SD card of the INSTAR camera and save them on your PC. Hold down the `}<strong parentName="p">{`CTRL`}</strong>{` key and use the mouse to select the pictures or videos you want to save from the 4th column. Then click on the `}<strong parentName="p">{`Download`}</strong>{` button below the 4th column. It opens the Windows Explorer with which you can select the folder in which the recordings should to be downloaded and saved. This will start the download process, which will be indicated by a progress bar on the left side of the `}<strong parentName="p">{`Download`}</strong>{` button. When the download is complete, a short message will appear. Confirm them with `}<strong parentName="p">{`Ok`}</strong>{`. If you want to download all the files of a folder, you do not have to select each file individually for mark a download. Just put the hook in the head of the last column to mark all recordings with one click.`}</p>
    <p>{`Please note that in this view it is not possible to view several pictures or videos at the same time. The `}<strong parentName="p">{`Multi Window`}</strong>{` button therefore has no effect here.`}</p>
    <p><img parentName="p" {...{
        "src": "/de/images/Software/Windows/InstarVision/Advanced/Player/Alarm_Recording_SD_Card.gif",
        "alt": "InstarVision: The INSTAR Player"
      }}></img></p>
    <h3 {...{
      "id": "media-view",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#media-view",
        "aria-label": "media view permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Media View`}</h3>
    <p>{`Click on the small left arrow in the header of the first column of the SD Card View to go to the Media View. This can be used to view any video files located on your PC. Click on the button (`}<strong parentName="p">{`Add`}</strong>{` button) under the first column. It opens the Windows Explorer with which you select the folder where the videos to be considered are located. The path to the selected folder is then displayed in the first column. After marking the path, all recognized video files are listed in the second column, which then can be started for viewing through a double-click.
You can now add more video folders in the same way. The videos in it are then appended to the list of videos in the second column. Please take note that videos from subfolders are not included. The videos must always be located directly in the selected folder.
After watching the video, the video display goes off with a black screen. However, if you want to watch all the videos in the second column one after another, put the hook in the head of the second column and then the next one will be automatically played after the selected video has been  expired. `}</p>
    <p>{`Please also note that in this view it is not possible to watch several videos at the same time. The `}<strong parentName="p">{`Multi Window`}</strong>{` button therefore has no effect here.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      